export default {
    namespaced: true,
    state(){
        return {
            items: [],
            payMethodCode: 0, // payMethodCode = 1 is balance, 2 is bank ticket, 0 is default or not setup
            ticketBankCode: null
        }
    },
    getters: {
        getItems: state => state.items,
        getPayMethodCode: state  => state.payMethodCode,
        getTicketBankCode: state => state.ticketBankCode
    },
    actions: {
        setItems({commit}, items) {
            commit('SET_ITEMS', items)
        },
        setPayMethodCode({commit}, code) {
            commit('SET_PAY_METHOD_CODE', code)
        },
        setTicketBankCode({commit}, code) {
            commit('SET_TICKET_BANK_CODE', code)
        }
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items
        },
        SET_PAY_METHOD_CODE(state, code) {
            state.payMethodCode = code === 1 || code === 2 ? code : 0
        },
        SET_TICKET_BANK_CODE(state, code) {
            state.ticketBankCode = code
        }
    }
}