export default {
    install(app) {
        const $router = app.config.globalProperties.$router
        if (!$router) {
            throw new Error("App Meta require router. Please install it after they are already installed.")
        }

        $router.beforeEach((to, from, next) => {
            Array.from(document.querySelectorAll('meta[app-meta]')).map(el=> el.parentNode.removeChild(el));
            const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
            if (nearestWithTitle) {
                document.title = nearestWithTitle.meta.title
            }

            next();
        });

        app.config.globalProperties.$appMeta = {
            setTitle(title) {
                document.title = title
                return this;
            },
            addLink(attrs, id) {
                const availableLink = id ? document.querySelectorAll(`link[app-meta-link="${id}"]`) : null

                if (!availableLink || (availableLink && availableLink.length === 0)) {
                    const link = document.createElement('link')
                    for (const key in attrs) {
                        link.setAttribute(key, attrs[key])
                    }

                    link.setAttribute("app-meta", '')
                    link.setAttribute("app-meta-link", id || '')

                    Array.from(document.getElementsByTagName("head")).map(el=> el.appendChild(link))
                }

                return this
            }
        }
    }
}