export default {
    install(app, options) {
        const {baseUrl} = options

        app.config.globalProperties.$smsPayAssets = {
            baseUrl(append) {
                return baseUrl + (append || '')
            }
        }
    }
}