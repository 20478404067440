export default {
    namespaced: true,
    state(){
        return {
            balance: 0,
            processed: false,
            feature: false
        }
    },
    getters: {
        getBalance: state => state.balance,
        isProcessed: state => state.processed,
        hasFeature: state => state.feature
    },
    actions: {
        setBalance({commit}, balance) {
            const numBalance = typeof balance === "number" ? balance : Number(balance)
            commit('SET_BALANCE', !isNaN(numBalance) ? numBalance : 0)
        },
        setBalanceAndFeature({commit}, data) {
            commit('SET_BALANCE_AND_FEATURE', data)
        },
        setProcessed({commit}, processed) {
            commit('SET_PROCESSED', processed)
        },
        setFeature({commit}, feature) {
            commit('SET_FEATURE', typeof feature === "boolean" ? feature : false)
        }
    },
    mutations: {
        SET_BALANCE(state, balance) {
            state.balance = balance
        },
        SET_BALANCE_AND_FEATURE(state, data) {
            const numBalance = typeof data?.balance === "number" ? data?.balance : Number(data?.balance)
            state.balance = !isNaN(numBalance) ? numBalance : 0
            state.feature = data?.feature || false
        },
        SET_PROCESSED(state, processed) {
            state.processed = processed
        },
        SET_FEATURE(state, feature) {
            state.feature = feature
        }
    }
}