export function isHttpErrorCode(code, error) {
    if (error.response) {
        const errorCode = error.response.data?.header?.error_code || null

        if (typeof code === "string") {
            return code === errorCode
        }

        for (const c of code) {
            if (c === errorCode) {
                return true
            }
        }
    }

    return false
}

export function httpErrorParse(error) {
    let message = 'Maaf, terjadi kesalahan yang tidak terduga.'
    const errorCode = error.response?.data?.header?.error_code || null

    if ("ERR_NETWORK" === error?.code) {
        message += ' (Pastikan koneksi internet Anda tersedia).'
    }

    if (error.response) {
        const payload = error.response?.data?.payload || null
        const messages = error.response?.data?.messages || null

        if (errorCode === 'auth.expired_token') {
            return {
                message: 'Sesi Anda telah berakhir. Silahkan untuk login kembali.',
                errorCode
            }
        }
        else if (errorCode === 'main_server.returns_failure') {
            const msg = payload?.msg || 'Unknown error message.'
            const rc = payload?.rc || null

            return {
                message: msg + (rc ? ' ('+rc+')' : ''),
                errorCode
            }
        }
        else if (errorCode === 'main_server.runtime_exception') {
            return {
                message: messages?.join(' '),
                errorCode
            }
        } else if (errorCode === 'validation.client_field_error') {
            const errors = []
            for (const field in messages) {
                errors.push(field + ': ' + messages[field].join(' '))
            }

            return {message: errors.join(' '), errorCode}
        }

        if (Array.isArray(messages)) {
            message = messages.join(' ')
            return {message, errorCode}
        }
    }

    return {message, errorCode}
}