export default {
    namespaced: true,
    state(){
        return {
            showSidebar: false,
            isVerified: false
        }
    },
    getters: {
        isShowSidebar: state => state.showSidebar,
        isVerified: state => state.isVerified
    },
    actions: {
        setShowSidebar({commit}, show) {
            commit('SET_SHOW_SIDEBAR', show)
        },
        setIsVerified({commit}, verifiedStatus) {
            commit('SET_IS_VERIFIED', verifiedStatus)
        }
    },
    mutations: {
        SET_SHOW_SIDEBAR(state, show) {
            state.showSidebar = show
        },
        SET_IS_VERIFIED(state, verifiedStatus) {
            state.isVerified = verifiedStatus
        }
    }
}